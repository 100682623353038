import { NavigationItem, PartnerFragment } from '@amf/shared/types/graphql';
import { ReactNode } from 'react';
import MainLayout from '@amf/shared/components/layout/Layout';
import Navigation from '@amf/shared/components/navigation/Navigation';
import BrandSuperliga from '@amf/shared/icons/brands/BrandSuperliga';
import { useTranslate } from '@tolgee/react';

import Footer from 'components/layout/Footer';
import { AppLinks, ExternalLinks } from 'utils/links';

interface LayoutProps {
  navigation: NavigationItem[];
  partners: PartnerFragment[];
  children: ReactNode;
}

function Layout({ navigation, partners, children }: LayoutProps) {
  const { t } = useTranslate();
  return (
    <MainLayout footer={<Footer partners={partners} />}>
      <Navigation
        variant='blue'
        navigation={navigation}
        brand={<BrandSuperliga />}
        search={AppLinks.search}
        buttonTitle={t('navigation.menu.account')}
        buttonLink={ExternalLinks.playerPortal}
        buttonProps={{
          variant: 'yellow',
        }}
      />
      {children}
    </MainLayout>
  );
}

export default Layout;
