export default function BrandSuperliga() {
  return (
    <svg
      width='223'
      height='80'
      viewBox='0 0 223 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M82.8138 37.0761C79.8008 37.0761 78.3363 35.6595 78.3363 32.745C78.3363 32.7185 78.3358 32.6947 78.3358 32.6735L82.0464 32.2205C82.0892 33.4889 82.6748 33.9879 84.0928 33.9879H87.2252C88.6997 33.9879 89.2742 33.4412 89.2742 32.0383V31.5678C89.2742 30.3079 88.667 29.9901 86.9578 29.7267L82.6219 29.0979C79.5159 28.6459 78.415 27.6006 78.415 25.1037V22.8796C78.415 19.9477 79.888 18.522 82.9179 18.522H88.3995C91.4305 18.522 92.9034 19.9387 92.9034 22.8531V22.9246L89.1928 23.3781C89.15 22.1093 88.5644 21.6107 87.147 21.6107H84.2233C82.7313 21.6107 82.122 22.1755 82.122 23.5599V23.9524C82.122 25.295 82.7652 25.5164 84.4664 25.767L88.8007 26.3959C91.9268 26.8462 92.9817 27.8677 92.9817 30.4425V32.7185C92.9817 35.651 91.5177 37.0761 88.5047 37.0761H82.8138Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M100.655 37.0763C97.6424 37.0763 96.1779 35.6512 96.1779 32.7187V18.7844H99.8859V32.0385C99.8859 33.4414 100.46 33.9881 101.934 33.9881H104.702C106.176 33.9881 106.75 33.4414 106.75 32.0385V18.7844H110.458V32.7187C110.458 35.6512 108.985 37.0763 105.954 37.0763H100.655Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M117.59 27.1052H122.144C123.619 27.1052 124.193 26.5584 124.193 25.156V23.822C124.193 22.4191 123.619 21.8723 122.144 21.8723H117.59V27.1052ZM113.882 36.8143V18.7841H123.397C126.428 18.7841 127.901 20.2093 127.901 23.1417V25.8363C127.901 28.7688 126.428 30.1939 123.397 30.1939H117.59V36.8143H113.882Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M130.742 36.8146V18.7844H143.299V21.8726H134.45V26.0326H142.203V29.1214H134.45V33.7264H143.299V36.8146H130.742Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M149.897 26.9752H154.504C155.979 26.9752 156.553 26.4284 156.553 25.0255V23.8223C156.553 22.4194 155.979 21.8726 154.504 21.8726H149.897V26.9752ZM157.582 36.8146L152.779 30.0634H149.897V36.8146H146.189V18.7844H155.783C158.796 18.7844 160.26 20.2096 160.26 23.142V25.7322C160.26 28.0242 159.36 29.3757 157.508 29.8642L157.091 29.9738L161.888 36.8146H157.582Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M164.937 36.8146V18.7844H168.645V33.7264H177.181V36.8146H164.937Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M179.936 36.8143H183.644V18.7841H179.936V36.8143Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M191.389 37.0761C188.376 37.0761 186.912 35.651 186.912 32.7185V22.8796C186.912 19.9477 188.376 18.522 191.389 18.522H197.002C200.032 18.522 201.505 19.9387 201.505 22.8531V22.9246L197.795 23.3781C197.752 22.1093 197.166 21.6107 195.749 21.6107H192.669C191.194 21.6107 190.62 22.1575 190.62 23.5599V32.0383C190.62 33.4412 191.194 33.9879 192.669 33.9879H195.749C197.223 33.9879 197.797 33.4486 197.797 32.0648V29.8274H193.647V26.7387H201.505V32.745C201.505 35.6595 200.032 37.0761 197.002 37.0761H191.389Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M210.534 29.1199H215.452L212.979 21.3414L210.534 29.1199ZM217.806 36.5922L216.477 32.411H209.483L208.153 36.5922H204.066L209.961 18.6288H216.025L221.894 36.5922H217.806Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0466 19.2762L14.5002 16.7236L11.9534 19.2762H9.76855V17.0855L12.3149 14.5329L9.76855 11.9803V9.78906H11.9534L14.5002 12.3416L17.0466 9.78906H19.2319V11.9798L16.6851 14.5329L19.2319 17.0855V19.2762H17.0466Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M56.0986 19.2762L53.5522 16.7231L51.0054 19.2762H48.8205V17.0855L51.3674 14.5329L48.8205 11.9803V9.78906H51.0054L53.5522 12.3416L56.0986 9.78906H58.2829V11.9798L55.7371 14.5329L58.2829 17.0855V19.2762H56.0986Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0466 47.9335L14.5002 45.3809L11.9534 47.9335H9.76855V45.7428L12.3149 43.1902L9.76855 40.6376V38.4469H11.9534L14.5002 40.9995L17.0466 38.4469H19.2319V40.6376L16.6851 43.1902L19.2319 45.7428V47.9335H17.0466Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M56.0986 47.9335L53.5522 45.3809L51.0054 47.9335H48.8205V45.7428L51.3674 43.1902L48.8205 40.6376V38.4469H51.0054L53.5522 40.9995L56.0986 38.4469H58.2829V40.6376L55.7371 43.1902L58.2829 45.7428V47.9335H56.0986Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.5728 62.2627H38.7577V60.0714L36.3176 57.6248L36.2108 57.5188L36.3176 57.4129L38.7577 54.9662V52.7755H36.5728L34.1322 55.2221L34.0265 55.3286L33.9197 55.2221L31.4802 52.7755H29.2948V54.9662L31.8411 57.5188L29.2948 60.0714V62.2627H31.4802L33.9197 59.816L34.0265 59.7096L34.1322 59.816L36.5728 62.2627Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.5728 33.6113L34.0265 31.0587L31.4796 33.6113H29.2948V31.4206L31.8411 28.868L29.2948 26.3154V24.1247H31.4796L34.0265 26.6773L36.5728 24.1247H38.7577V26.3154L36.2108 28.868L38.7577 31.4206V33.6113H36.5728Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.92844 54.8948L34.0245 76.0625L65.0937 54.915V2.96318H2.95909L2.92844 54.8948ZM0 56.4551L0.00369952 0H68.048L68.0491 56.5134L34.0245 79.6509L0 56.4551Z'
        fill='#FFC81A'
      />
    </svg>
  );
}
